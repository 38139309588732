import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import _ from 'lodash';
import TermsOfServicesContent from 'components/Common/TermsOfServicesContent';
import { useAppContext } from 'components/AppContext';
import * as api from 'utils/api';
import config from 'configuration';
import useTranslation from '../../hooks/useTranslation';

export default function AcceptTermsOfServices() {
  const { tCommon } = useTranslation();
  const { request, toastError, getInfo } = useAppContext();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const title = tCommon('termsOfServices').split(' ');
  title[2] = _.capitalize(title[2]);

  return (
    <div className="accept-terms-of-services">
      <div className="content-wrapper">
        {config.termsOfServicesUrl ? (
          <div>
            <h1>{title.join(' ')}</h1>
            <a target="_blank" href={config.termsOfServicesUrl} rel="noreferrer">{config.termsOfServicesUrl}</a>
          </div>
        ) : (
          <TermsOfServicesContent />
        )}
        <div className="accept-area">
          <Form.Group>
            <Form.Check
              type="checkbox"
              label={tCommon('acceptTermsOfServices')}
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
          </Form.Group>
          <Button
            disabled={!checked || loading}
            onClick={async () => {
              setLoading(true);
              const { success, error } = await request(api.updateInfo({
                readTos: true,
              }));

              if (success) {
                await getInfo();
              } else {
                toastError(error);
                setLoading(false);
              }
            }}
          >
            {tCommon('next')}
          </Button>
        </div>
      </div>
    </div>
  );
}
